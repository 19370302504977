<template>
  <main class="careers">
    <Heading
      value="Careers"
      typo="1"
    />
    <Forms
      :data="{ value: { form: 'careers' } }"
      class="block"
    />
  </main>
</template>

<script>
import Forms from '@/components/forms';
import Heading from '@/components/typo/heading.vue';

export default {
  name: 'Careers',
  components: {
    Forms,
    Heading,
  },
};
</script>

<style lang="scss" scoped>
.careers {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: repeat(var(--main-columns), minmax(0, 1fr));
  column-gap: var(--column-gap-s);
  padding: 0 var(--spacer-s);
  padding-bottom: var(--spacer-xxl);

  .heading,
  .form {
    padding-top: var(--spacer-xxl);
  }
}
</style>
