<template>
  <form @submit.prevent="submit">
    <Input
      type="text"
      name="name"
      required
      @update="updateMail"
    />
    <Input
      type="text"
      name="surname"
      required
      @update="updateMail"
    />
    <Input
      type="text"
      name="phone"
      required
      :columns="2"
      @update="updateMail"
    />
    <Input
      type="email"
      name="personal_email"
      required
      @update="updateMail"
    />
    <Input
      type="email"
      name="professional_email"
      required
      @update="updateMail"
    />
    <Input
      type="text"
      name="address"
      required
      @update="updateMail"
    />
    <Input
      type="text"
      name="postcode"
      required
      @update="updateMail"
    />
    <Input
      type="text"
      name="city"
      required
      @update="updateMail"
    />
    <Input
      type="text"
      name="country"
      required
      @update="updateMail"
    />
    <!-- <Radio
      name="role"
      required
      :options="['professional', 'publicist']"
      @update="updateMail"
    />
    <Input
      type="text"
      name="register"
      required
      @update="updateMail"
    /> -->
    <Input
      type="text"
      name="newspaper"
      required
      :columns="2"
      @update="updateMail"
    />
    <div class="typo--7">
      {{ `${$labels().form_subject} *` }}
    </div>
    <!-- <Textarea
      name="subject"
      :columns="2"
      :rows="2"
      required
      @update="updateMail"
    /> -->
    <Radio
      name="entry"
      :options="['free', 'contact']"
      @update="updateMail"
    />
    <Textarea
      name="other"
      :columns="2"
      :rows="2"
      @update="updateMail"
    />
    <Checkbox
      name="newsletter"
      :options="['newsletter']"
      @update="updateNewsletter"
    />
    <Checkbox
      name="gdpr"
      :options="['gdpr']"
      required
      @update="updateMail"
    />
    <Link
      class="submit"
      theme="highlight"
      :disabled="!valid || response === 'success' || loading"
      :data="{
        fn: () => {},
        title: $labels().form_submit,
      }"
    />
    <div
      v-if="response"
      class="typo--7 response"
    >
      {{ `${$labels()[`form_${response}_message`]}` }}
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

import elements from '@/components/forms/elements.js';
import Link from '@/components/typo/link';

export default {
  components: {
    ...elements,
    Link,
  },
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: {},
      newsletter: false,
      newsletterResponse: '',
      response: '',
    };
  },
  computed: {
    ...mapGetters(['options', 'lang', 'loading']),
  },
  methods: {
    updateMail(name, model, type = false) {
      this.email[name] = {
        label: type === 'choice' ? model : this.$labels()[`form_${name}`],
        value: type === 'choice' ? true : model,
      };
      if (name === 'personal_email' || name === 'professional_email') {
        this.response = undefined;
      }
    },
    updateNewsletter(name, model) {
      this.newsletter = model;
    },
    submit() {
      this.$store.commit('SET_LOADING', true);
      const request = {
        fn: 'form',
        params: {
          name: 'press',
          subject: this.$labels().form_press_subject,
          email: this.email,
          language: this.lang,
        },
      };

      // window.grecaptcha.ready(() => {
      //   window.grecaptcha
      //     .execute(this.options.extra.recaptcha_public_key.value, {
      //       action: 'submit',
      //     })
      //     .then((token) => {
      //       request.params.token = token;

      this.$store
        .dispatch('sendRequest', request)
        .then((data) => {
          if (data.error) {
            this.response = 'error';
            console.log(data.message);
          } else {
            this.response = 'success';
          }
          this.$store.commit('SET_LOADING', false);
        })
        .catch((error) => {
          this.response = 'error';
          console.log(error);
          this.$store.commit('SET_LOADING', false);
        });
      //     });
      // });

      if (this.newsletter) {
        const newsletterRequest = {
          fn: 'sendinblue',
          params: {
            list_id: this.options.extra.sendinblue_list_id.value,
            email: this.email.personal_email.value,
          },
        };

        this.$store
          .dispatch('sendRequest', newsletterRequest)
          .then((response) => {
            const { data } = response;

            if (
              data.contacts
              && data.contacts.success.length > 0
              && data.contacts.success.includes(this.email)
            ) {
              this.newsletterResponse = 'success';
            } else if (data.code && data.code === 'invalid_parameter') {
              this.newsletterResponse = 'already';
            } else {
              this.newsletterResponse = 'error';
            }
          })
          .catch((error) => {
            this.newsletterResponse = 'error';
            console.log(error);
          });
      }
    },
  },
};
</script>

<style></style>
