var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{class:[
    'thumb',
    'thumb--default',
    _vm.pinned ? 'thumb--pinned' : false,
    `thumb--${_vm.data.type}`,
  ],attrs:{"to":_vm.$relativeUrl(_vm.data.link)}},[_c('Figure',{attrs:{"theme":_vm.pinned ? 'cover' : 'thumb',"data":{
      value: {
        src: _vm.data.gds_featured_image,
      },
      settings: {},
    }}}),_c('div',{staticClass:"details"},[_c('Duration',{attrs:{"show-time":false,"duration":_vm.data.acf.duration.value}}),(_vm.space)?_c('div',{staticClass:"space typo--p-small t-ellipsis"},[_vm._v(" "+_vm._s(_vm.space)+" ")]):_vm._e()],1),_c('div',{class:['title', _vm.pinned ? 'typo--3--trimmed' : 'typo--nav--trimmed'],domProps:{"innerHTML":_vm._s(_vm.data.title.rendered)}}),(_vm.eventCat)?_c('div',{staticClass:"event-category typo--6--trimmed invert"},[_vm._v(" "+_vm._s(_vm.eventCat)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }