const labels = {
  it: {
    archive_filters: 'Filtra',
    archive_filters_all: 'Tutti',
    back_cta: 'Indietro',
    back_home_cta: 'Torna alla home',
    back_parent_cta: 'Torna a',
    close_cta: 'Chiudi',
    credits: 'website by Giga Design Studio',
    curation_label: 'A cura di',
    date_picker_cta: 'Seleziona data',
    duration_label: 'Data',
    empty_event_archive_message: 'Nessun evento per i filtri selezionati.',
    empty_exhibition_archive_message: 'Nessuna mostra per i filtri selezionati.',
    events_archive_title: 'Calendario eventi',
    form_address: 'Indirizzo',
    form_city: 'Città',
    form_contact: 'Voglio entrare in contatto con l’ufficio stampa per avere un accredito',
    form_country: 'Nazione',
    form_error: 'Campo errato',
    form_error_message: 'C\'è stato un errore nell\'invio.',
    form_free: 'Sono in Pinacoteca e voglio accreditarmi',
    form_name: 'Nome',
    form_newsletter: 'Voglio ricevere la newsletter',
    form_gdpr:
      'Presa visione informativa sul trattamento dei dati personali ex. Artt. 13-14 reg.to UE 2016/679 per soggetti interessati alla <a href=\'https://www.pinacoteca-agnelli.it/wp-content/uploads/2023/10/12014_51_361261_2242685_Informativa_a__Interessati_alla_newsletter.pdf\' target=\'_blank\'>newsletter</a>, <a href=\'https://www.pinacoteca-agnelli.it/wp-content/uploads/2024/05/12014_51_377611_2265221_Informativa_a__giornalisti_e_pubblicisti.pdf\' target=\'_blank\'>giornalisti e pubblicisti</a>',
    form_careers:
      'Autorizzo il trattamento dei dati personali contenuti nel mio curriculum vitae ai sensi del D.Lgs. 196/2003 come novellato dal D.Lgs. 101/2018 e dell\'Art. 13 del GDPR (Reg. UE 679/2016)',
    form_newspaper: 'Testata',
    form_other: 'Altro',
    form_email: 'Email',
    form_personal_email: 'Email personale',
    form_phone: 'Recapito telefonico',
    form_postcode: 'CAP',
    form_press_subject: 'Accredito stampa',
    form_careers_subject: 'Candidatura',
    form_professional_email: 'Email aziendale',
    form_professional: 'Professionista',
    form_publicist: 'Pubblicista',
    form_register: 'Ordine dei giornalisti di',
    form_role: 'Ruolo',
    form_submit: 'Invia',
    form_success_message: 'Richiesta inviata.',
    form_subject: 'Richiesta',
    form_surname: 'Cognome',
    form_message: 'Messaggio',
    form_research_publishing: 'Ricerca ed editoria',
    form_exhibition_production: 'Produzione mostre',
    form_marketing_communication: 'Marketing e comunicazione',
    form_project_management: 'Project management',
    form_collection: 'Collezione',
    form_administration: 'Amministrazione',
    form_education: 'Educazione',
    form_cv: 'Curriculum Vitae (2MB max)',
    form_file_placeholder: 'Carica file...',
    form_file_error: 'Il file deve essere sotto i 2MB',
    information_label: 'Informazioni',
    menu_cta: 'Menu',
    newsletter_cta: 'Invia',
    newsletter_already_message: 'Email già registrata.',
    newsletter_error_message: 'Si è verificato un errore.',
    newsletter_success_message: 'Iscrizione avvenuta!',
    not_found_message: '404 pagina non trovata',
    reset_cta: 'Cancella',
    space_label: 'Spazio',
    today: 'Oggi',
  },
  en: {
    archive_filters: 'Filter',
    archive_filters_all: 'All',
    back_cta: 'Back',
    back_home_cta: 'Back to home',
    back_parent_cta: 'Go back to',
    close_cta: 'Close',
    credits: 'website by Giga Design Studio',
    curation_label: 'Curated by',
    date_picker_cta: 'Select date',
    duration_label: 'Date',
    empty_event_archive_message: 'No events with the selected filters.',
    empty_exhibition_archive_message: 'No exhibitions with the selected filters.',
    events_archive_title: 'Events calendar',
    form_address: 'Address',
    form_city: 'City',
    form_contact: 'I am a journalist and I would like to receive complimentary access only.',
    form_country: 'Nation',
    form_error: 'Wrong field',
    form_error_message: 'Failed to send enquiry.',
    form_free:
      'I am planning an article on Pinacoteca Agnelli and would like to receive press information and complimentary access. ',
    form_name: 'Name',
    form_newsletter: 'I would like to receive the newsletter',
    form_gdpr:
      'I accept the Privacy Policy concerning the processing of personal data pursuant to articles 13-14 of (EU) Regulation 2016/679. Data Subject: <a href=\'https://www.pinacoteca-agnelli.it/wp-content/uploads/2024/05/12014_51_361261_2219829_Informativa_a__Newsletter_data_subject.pdf\' target=\'_blank\'>Newsletter</a> and <a href=\'https://www.pinacoteca-agnelli.it/wp-content/uploads/2024/05/12014_51_377611_2219825_Informativa_a__Journalists_and_publishers.pdf\' target=\'_blank\'>Journalists and publishers</a>',
    form_careers:
      'I authorize the processing of personal data contained in my resume in accordance with Legislative Decree 196/2003 as amended by Legislative Decree 101/2018 and Article 13 of the GDPR (Regulation EU 679/2016).',
    form_newspaper: 'News media',
    form_other: 'Other',
    form_email: 'Email',
    form_personal_email: 'Personal email',
    form_phone: 'Phone number',
    form_postcode: 'Postcode',
    form_press_subject: 'Press accreditation',
    form_careers_subject: 'Application',
    form_professional_email: 'Professional email',
    form_professional: 'Professional',
    form_publicist: 'Publicist',
    form_register: 'Journalist order',
    form_role: 'Role',
    form_submit: 'Send',
    form_success_message: 'Enquiry submitted.',
    form_subject: 'Enquiry',
    form_surname: 'Surname',
    form_message: 'Message',
    form_research_publishing: 'Research and publishing',
    form_exhibition_production: 'Exhibition production',
    form_marketing_communication: 'Marketing and communication',
    form_project_management: 'Project management',
    form_collection: 'Collection',
    form_administration: 'Administration',
    form_education: 'Education',
    form_cv: 'Curriculum Vitae (2MB max)',
    form_file_placeholder: 'Upload file...',
    form_file_error: 'File size must be under 2MB',
    information_label: 'Information',
    menu_cta: 'Menu',
    newsletter_cta: 'Send',
    newsletter_already_message: 'Email already registered.',
    newsletter_error_message: 'There was an error.',
    newsletter_success_message: 'Subscribed!',
    not_found_message: '404 page not found',
    reset_cta: 'Reset',
    space_label: 'Space',
    today: 'Today',
  },
};

labels.install = (vm, store) => {
  vm.prototype.$labels = () => labels[store.state.lang];
};

export default labels;
