var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['gutenberg', _vm.theme ? `gutenberg--${_vm.theme}` : false]},_vm._l((_vm.data),function(block,index){return _c(_vm.components.get(_vm.parseBlockName(block.blockName)),{key:index,tag:"component",class:[
      'block',
      _vm.spacing(block),
      index === _vm.data.length - 1 ? 'block--last' : false,
      `block--${index}`,
    ],attrs:{"data":block.blockName.includes('acf/')
        ? { ...block.attrs.data, position: index }
        : { ...block, position: index }}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }