var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"single--event"},[_c('article',[_c('Heading',{attrs:{"value":_vm.post.title.rendered,"typo":"3","tag":"h1","theme":"full"}}),_c('Sidebar',{attrs:{"allowed-sections":_vm.sidebarSections,"post":_vm.post,"tickets":_vm.post.acf.tickets.value.tickets}}),_c('main',[_c('Gutenberg',{attrs:{"theme":"no-padding","data":_vm.post.gds_blocks}})],1)],1),_c('footer',[_c('Heading',{attrs:{"value":_vm.$labels().events_archive_title,"typo":"nav-l"}}),_c('Archive',{attrs:{"data":{
        value: {
          post_type: 'event',
        },
        settings: {
          posts_per_page: _vm.$mq.isMobile ? 4 : 3,
          filters: ['agenda:scheduled'],
          exclude: [_vm.post.id]
        },
      }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }