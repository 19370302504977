var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"thumb thumb--work",attrs:{"to":_vm.$relativeUrl(_vm.data.link)}},[_c('Figure',{attrs:{"theme":"thumb","data":{
      value: {
        src: _vm.data.gds_featured_image,
      },
      settings: {},
    }}}),_c('div',[(_vm.artist)?_c('div',[_vm._v(" "+_vm._s(_vm.artist)+" ")]):_vm._e(),_c('div',{class:[ 'title', _vm.data.acf.display_title.value.title
        ? false : 't-italic', ],domProps:{"innerHTML":_vm._s(_vm.title)}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }