<template>
  <div
    :class="['element', required && 'element--required']"
    :style="{ gridColumn: !$mq.isMobileS ? `span ${columns}` : 'initial' }"
  >
    <label
      class="typo--7"
      :for="name"
    >
      {{ $labels()[`form_${name}`] }}
    </label>
    <div class="input-container">
      <input
        :id="name"
        size="1"
        :required="required"
        :name="name"
        type="file"
        :accept="accept"
        @change="updateModel"
      >
      <input
        :id="`${name}-overlay`"
        v-model="model"
        size="1"
        :name="`${name}-overlay`"
        type="text"
        :placeholder="$labels()[`form_file_placeholder`]"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 4,
    },
    accept: {
      type: String,
      default: 'application/pdf',
    },
  },
  data() {
    return {
      model: '',
    };
  },
  methods: {
    updateModel(event) {
      const file = event.target.files[0];
      const maxSize = 2 * 1024 * 1024;
      if (file.size <= maxSize) {
        this.model = event.target.files[0].name;
        this.$emit('update', this.name, event.target.files[0], 'file');
      } else {
        this.model = this.$labels().form_file_error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.element {
  display: grid;
  width: 100%;
  align-items: start;

  label {
    cursor: text;
  }

  .input-container {
    display: grid;
    & > * {
      grid-area: 1 / 1;
    }
  }

  input {
    border-bottom: var(--line) solid var(--alpha-black);

    @media (hover: hover) {
      &:hover {
        opacity: 1;
        border-color: var(--black);
      }
    }

    &:focus {
      opacity: 1;
      border-color: var(--black);
    }
  }

  input[type="file"] {
    opacity: 0;
    z-index: 1;
  }

  &--required {
    label {
      &:after {
        content: "*";
      }
    }
  }
}
</style>
