var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"not-found"},[_c('Heading',{staticClass:"block block--l",style:(_vm.$mq.isMobile ? { paddingTop: '116px' } : false),attrs:{"data":{
      value: { heading: _vm.$labels().not_found_message },
      settings: {
        typo: '2',
      },
    }}}),_c('Link',{staticClass:"block",attrs:{"data":{
      url: _vm.lang === _vm.defaultLang ? '/' : `/${_vm.lang}/`,
      title: _vm.$labels().back_home_cta,
    },"theme":"full"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }