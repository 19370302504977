var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('Input',{attrs:{"type":"text","name":"name","required":""},on:{"update":_vm.updateMail}}),_c('Input',{attrs:{"type":"text","name":"surname","required":""},on:{"update":_vm.updateMail}}),_c('Input',{attrs:{"type":"email","name":"email","required":""},on:{"update":_vm.updateMail}}),_c('File',{attrs:{"name":"cv","required":""},on:{"update":_vm.updateMail}}),_c('Radio',{attrs:{"name":"area","required":"","options":[
      'research_publishing',
      'exhibition_production',
      'marketing_communication',
      'project_management',
      'collection',
      'administration',
      'education',
    ]},on:{"update":_vm.updateMail}}),_c('Textarea',{attrs:{"name":"message","required":"","columns":2,"rows":2},on:{"update":_vm.updateMail}}),_vm._v(" "),_c('Checkbox',{attrs:{"name":"careers","options":['careers'],"required":""},on:{"update":_vm.updateMail}}),_c('Link',{style:({ gridColumn: '1 / -1' }),attrs:{"theme":"inline","data":{
      url: '/privacy',
      title: 'Privacy policy',
    }},nativeOn:{"click":function($event){$event.preventDefault();}}}),_c('Link',{staticClass:"submit",attrs:{"theme":"highlight","disabled":!_vm.valid || _vm.response === 'success' || _vm.loading,"data":{
      fn: () => {},
      title: _vm.$labels().form_submit,
    }}}),(_vm.response)?_c('div',{staticClass:"typo--7 response"},[_vm._v(" "+_vm._s(`${_vm.$labels()[`form_${_vm.response}_message`]}`)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }