<template>
  <footer
    v-show="!$route.meta.hideFooter"
    class="footer"
  >
    <div class="desktop invert">
      <div class="columns">
        <div
          v-for="(column, index) in footer"
          :key="index"
          class="column typo--caption"
        >
          <component
            :is="components.get(column.acf_fc_layout)"
            :value="column.value"
            typo="caption"
          />
          <br>
          <Link
            v-show="index === 2"
            class="credits"
            theme="inline--small"
            :data="{
              title: $labels().credits,
              url: 'https://www.gigadesignstudio.com/',
              target: '_blank',
            }"
          />
        </div>
      </div>
      <div class="bottom">
        <router-link
          class="logo"
          to="/"
        >
          <inline-svg :src="require(`@/assets/svg/logo-icon.svg`)" />
        </router-link>
        <a
          href="https://www.fiat.it/"
          target="_blank"
          class="fiat"
        >
          <Richtext
            value="Main partner"
            typo="7--trimmed"
          />
          <inline-svg :src="require(`@/assets/svg/fiat.svg`)" />
        </a>
        <a
          href="https://www.lingotto.com/"
          target="_blank"
          class="lingotto"
        >
          <Richtext
            value="Official sponsor"
            typo="7--trimmed"
          />
          <inline-svg :src="require(`@/assets/svg/lingotto.svg`)" />
        </a>
      </div>
    </div>
    <div class="bottom--mobile">
      <router-link
        class="logo"
        to="/"
      >
        <inline-svg :src="require(`@/assets/svg/logo-icon.svg`)" />
      </router-link>
      <div class="logos-container">
        <a
          href="https://www.fiat.it/"
          target="_blank"
          class="fiat"
        >
          <Richtext
            value="Main partner"
            typo="7--trimmed"
          />
          <inline-svg :src="require(`@/assets/svg/fiat.svg`)" />
        </a>
        <a
          href="https://www.lingotto.com/"
          target="_blank"
          class="lingotto"
        >
          <Richtext
            value="Official sponsor"
            typo="7--trimmed"
          />
          <inline-svg :src="require(`@/assets/svg/lingotto.svg`)" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';
import Richtext from '@/components/typo/richtext';
import Link from '@/components/typo/link';
import Menu from '@/components/ui/footer-menu';

export default {
  name: 'Footer',
  components: {
    Richtext,
    Link,
  },
  data() {
    return {
      components: new Map([
        ['text', Richtext],
        ['menu', Menu],
      ]),
    };
  },
  computed: {
    ...mapGetters(['options', 'currentPost', 'lang']),
    footer() {
      const { footer, 'footer-en': footerEn } = this.options;
      return this.lang === 'en' ? footerEn : footer;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: var(--black);

  @include mq(m) {
    background: initial;
  }

  .desktop {
    display: grid;
    row-gap: var(--row-gap-xxl);
    padding: var(--spacer-s);

    @include mq(m) {
      border-radius: var(--border-radius);
    }

    > * {
      column-gap: var(--column-gap-s);
      display: grid;
      grid-template-columns: repeat(var(--main-columns), minmax(0, 1fr));
      justify-items: start;
      row-gap: var(--column-gap-l);
    }

    .column {
      grid-column: span 4;

      @include mq(m) {
        grid-column: span 3;

        &:first-child {
          grid-column: 2 / span 3;
        }
      }

      &:last-child {
        grid-column: span 2;
      }
    }

    .logo {
      display: grid;
      grid-column-start: 2;

      svg {
        margin-bottom: -10%;
        height: auto;
        width: 36px;
      }
    }
  }

  .bottom {
    display: none;
    align-items: end;

    @include mq(m) {
      display: grid;
    }

    &--mobile {
      align-items: center;
      background: var(--red);
      border-radius: var(--border-radius);
      column-gap: var(--column-gap-s);
      display: grid;
      grid-template-columns: repeat(var(--main-columns), minmax(0, 1fr));
      justify-items: start;
      padding: var(--spacer-bar-s) var(--spacer-bar-m);

      @include mq(m) {
        display: none;
      }

      > * {
        grid-column: span 4;
      }

      .logos-container {
        display: flex;
        gap: var(--spacer-bar-m);
        flex-wrap: wrap;
      }

      .logo {
        display: grid;

        svg {
          margin-top: 6px;
          width: auto;
          height: 29px;
        }
      }
    }
  }

  .fiat {
    align-items: center;
    display: grid;
    gap: var(--column-gap-bar-s);
    grid-auto-flow: column;

    @include mq(m) {
      grid-column: -6 / span 2;
    }

    svg {
      width: auto;
      height: 22px;

      @include mq(m) {
        height: 36px;
      }
    }
  }
  .lingotto {
    align-items: center;
    display: grid;
    gap: var(--column-gap-bar-s);
    grid-auto-flow: column;

    @include mq(m) {
      grid-column: -4 / span 3;
    }

    svg {
      width: auto;
      height: 22px;

      @include mq(m) {
        height: 36px;
      }
    }
  }
}
</style>
