var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'gutenberg',
    'group',
    'full',
    _vm.background ? `group--${_vm.background}` : false,
  ],style:({
    background: _vm.background ? `var(--${_vm.background})` : false,
    color: _vm.color,
    fill: _vm.color,
    paddingBottom: _vm.paddingBottom,
  })},_vm._l((_vm.data.innerBlocks),function(block,index){return _c(_vm.components.get(_vm.parseBlockName(block.blockName)),{key:index,tag:"component",class:[
      'block',
      _vm.spacing(block),
      `block--${index}`,
      _vm.background ? `block--${_vm.background}` : false,
    ],style:(index === 0 && _vm.data.position === 0 && _vm.$mq.isMobile
        ? { paddingTop: '116px' }
        : false),attrs:{"data":_vm.blockData(block)}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }