var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'link',
    _vm.computedTheme ? `link--${_vm.computedTheme}` : false,
    _vm.computedTheme === 'huge' ? `link--huge--${_vm.data.settings.background}` : '',
    _vm.data.size ? `typo--${_vm.data.size}` : false,
    _vm.disabled ? 'link--disabled' : false,
    _vm.data.classes,
  ],on:_vm._d({},[_vm.event,_vm.data.fn])},[(_vm.iconBefore)?_c('Icon',{attrs:{"name":_vm.iconBefore,"to":_vm.url}}):_vm._e(),_c('div',{staticClass:"link__text"},[_c(_vm.tag,{tag:"component",attrs:{"href":_vm.external ? _vm.url : undefined,"to":!_vm.external ? _vm.url : undefined,"target":_vm.link.target},domProps:{"innerHTML":_vm._s(_vm.title)}})],1),(_vm.iconAfter)?_c('Icon',{style:({
      transform: _vm.icon && _vm.icon.rotation ? `rotate(${_vm.icon.rotation}deg)` : null,
    }),attrs:{"name":_vm.iconAfter,"to":_vm.url}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }