<template>
  <component
    :is="mergeProps.link ? (externalUrl ? 'a' : 'RouterLink') : 'div'"
    :href="externalUrl ? mergeProps.link.url : undefined"
    :to="!externalUrl ? mergeProps.link.url : undefined"
    :target="externalUrl ? '_blank' : '_self'"
    :class="[
      'heading',
      `heading--${mergeProps.link ? 'link' : mergeProps.typo}`,
      `heading--${mergeProps.typo}${
        mergeProps.theme ? `--${mergeProps.theme}` : ''
      }`,
    ]"
  >
    <component
      :is="mergeProps.tag"
      :class="['title', `typo--${mergeProps.typo}${trim ? '--trimmed' : ''}`]"
      v-html="mergeProps.value"
    />
    <Link
      v-if="mergeProps.link"
      theme="full"
      :data="{
        url: mergeProps.link.url,
        title: mergeProps.link.title,
        target: mergeProps.link.target,
      }"
    />
  </component>
</template>

<script>
import Link from '@/components/typo/link';

export default {
  name: 'Heading',
  components: {
    Link,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: () => {},
    },
    typo: {
      type: String,
      default: () => {},
    },
    tag: {
      type: String,
      default: () => {},
    },
    theme: {
      type: [Boolean, String],
      default: false,
    },
    trim: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    mergeProps() {
      let tag = 'h2';
      if (this.tag && this.tag !== '') {
        tag = this.tag;
      }
      const typoTags = new Map([
        ['1', 'h2'],
        ['2', 'h3'],
        ['3', 'h4'],
        ['4', 'h5'],
      ]);

      const typoMap = new Map([
        ['1', '1'],
        ['2', '2'],
        ['3', '4'],
        ['4', '5'],
      ]);

      if (this.data && this.data.settings?.typo) {
        tag = typoTags.get(this.data.settings?.typo);
      }

      let link = false;

      if (this.data && this.data.value.link) {
        link = this.data.value.link;
      }

      let { theme } = this;

      if (!theme && this.data && this.data.settings.style !== 'default') {
        theme = this.data.settings.style;
      }

      return {
        typo: this.typo
          ? this.typo
          : this.data
            ? typoMap.get(this.data.settings?.typo)
            : 'default',
        value: this.value || this.data.value?.heading,
        tag,
        link,
        theme,
      };
    },
    externalUrl() {
      return (
        this.mergeProps.link.url
        && typeof this.mergeProps.link.url === 'string'
        && this.mergeProps.link.url.startsWith('http')
      );
    },
    url() {
      return this.externalUrl
        ? this.$relativeUrl(this.mergeProps.link.url)
        : this.mergeProps.link.url;
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  @include mq(m) {
    grid-column: 1 / span 9;
  }

  &--1 {
    &--indent {
      grid-column-start: 2;
    }
  }

  &--link {
    align-items: end;
    display: grid;
    column-gap: var(--column-gap-s);
    grid-column: 1 / -1;
    grid-template-columns: repeat(var(--main-columns), minmax(0, 1fr));
    row-gap: var(--row-gap-s);

    .title {
      grid-column: 1 / -1;

      @include mq(m) {
        grid-column: 1 / span 9;
      }
    }

    .link {
      @include mq(m) {
        grid-column: span 3;
        justify-self: end;
      }
    }
  }

  &--1-serif {
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: span 4;
    }

    &--italic {
      font-style: italic;
    }

    /deep/ em {
      font-style: italic;
    }
  }

  &--2 {
    grid-column: 1 / -1;

    &--indent {
      grid-column-start: 2;
    }
  }

  &--nav-l {
    grid-column: 1 / -1;
  }

  &--3 {
    grid-column: 1 / -1;

    @include mq(m) {
      grid-column: 1 / span 9;
    }

    &--full {
      @include mq(m) {
        grid-column: 1 / -1;
      }
    }
  }

  &--4 {
    max-width: 700px;

    @include mq(s) {
      grid-column: 2 / -1;
    }

    @include mq(m) {
      grid-column: 4 / span 8;
      max-width: 1000px;
    }

    &--full {
      @include mq(s) {
        grid-column: 1 / -1;
      }
      @include mq(m) {
        grid-column: 1 / span 8;
      }
    }
  }

  &--5 {
    @include mq(s) {
      grid-column: 2 / span 6;
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }
}
</style>
