<template>
  <div
    :class="[
      'link',
      computedTheme ? `link--${computedTheme}` : false,
      computedTheme === 'huge' ? `link--huge--${data.settings.background}` : '',
      data.size ? `typo--${data.size}` : false,
      disabled ? 'link--disabled' : false,
      data.classes,
    ]"
    @[event]="data.fn"
  >
    <Icon
      v-if="iconBefore"
      :name="iconBefore"
      :to="url"
    />
    <div class="link__text">
      <component
        :is="tag"
        :href="external ? url : undefined"
        :to="!external ? url : undefined"
        :target="link.target"
        v-html="title"
      />
    </div>
    <Icon
      v-if="iconAfter"
      :name="iconAfter"
      :style="{
        transform: icon && icon.rotation ? `rotate(${icon.rotation}deg)` : null,
      }"
      :to="url"
    />
  </div>
</template>

<script>
import Icon from '@/components/ui/icon';

export default {
  name: 'Link',
  components: {
    Icon,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    theme: {
      type: [Boolean, String],
      default: false,
    },
    icon: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    event() {
      return this.data.fn ? 'click' : null;
    },
    tag() {
      return this.data.fn ? 'button' : this.external ? 'a' : 'router-link';
    },
    link() {
      const value = this.data.value ? { ...this.data.value.link } : { ...this.data };
      if (this.computedTheme === 'huge') {
        let letters = Array.from(value.title);
        letters = letters.map((letter) => `<span class='${letter.toLowerCase()}'>${letter}</span>`);
        value.title = letters.join('');
      }
      return value;
    },
    title() {
      return this.link.title;
    },
    url() {
      if (this.link.url && typeof this.link.url === 'string') {
        return this.$relativeUrl(this.link.url);
      }
      return this.link.url;
    },
    iconBefore() {
      let value = false;
      if (this.icon && this.icon.position === 'before') {
        value = this.icon.name;
      }
      if (this.computedTheme === 'full--invert') {
        value = 'arrow-left';
      }
      return value;
    },
    iconAfter() {
      let value = false;
      if (this.icon && this.icon.position === 'after') {
        value = this.icon.name;
      }
      if (this.computedTheme === 'full') {
        value = 'arrow-right';
      }
      return value;
    },
    external() {
      return this.url && typeof this.url === 'string' && this.url.startsWith('http');
    },
    computedTheme() {
      return this.theme ? this.theme : this.data.settings ? this.data.settings.theme : 'default';
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  &--default {
    @include mq(s) {
      grid-column-start: 2;
    }
    @include mq(m) {
      grid-column-start: 4;
    }
  }

  &--highlight,
  &--default,
  &--full--background {
    justify-self: start;

    .link__text {
      @extend %typo--link;

      a,
      button {
        background: var(--black);
        padding: calc(var(--spacer-xs) * 2);
        padding-top: 7px;
        padding-bottom: 1px;
        color: var(--white);
        display: grid;
        text-decoration: none;
        transition: color 0.2s ease;
      }
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;

        a,
        button {
          color: var(--red);
        }
      }
    }
  }

  &--default {
    &.block {
      &--black,
      &--light-black {
        a,
        button {
          background: var(--white);
          color: var(--black);
        }

        @media (hover: hover) {
          &:hover {
            opacity: 1;

            a,
            button {
              color: var(--red);
            }
          }
        }
      }
    }
  }

  &--full,
  &--full--invert {
    align-items: end;
    display: grid;
    grid-auto-flow: column;
    grid-column: 1 / -1;
    max-width: max-content;

    .link__text {
      @extend %typo--link--trimmed;

      a {
        display: grid;
        text-decoration: none;
      }
    }

    .icon {
      padding-left: var(--spacer-s);
    }
  }

  &--full--invert {
    .icon {
      padding: 0;
      padding-right: var(--spacer-s);
    }
  }

  &--full--background {
    .link__text {
      a,
      button {
        box-sizing: border-box;
        height: var(--bar-height);
        padding: 0 var(--spacer-s);
        padding-top: 16px;
        width: 100%;

        @include mq(m) {
          padding-top: 18px;
        }
      }
    }
  }

  &--icon {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;

    .link__text {
      @extend %typo--link--trimmed;
    }

    .icon {
      transition: transform 0.2s ease;

      @include mq(m) {
        padding: 0 var(--spacer-m);
      }

      @media (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &--link {
    .link__text {
      @extend %typo--link--trimmed;

      a {
        display: grid;
        text-decoration: none;
      }
    }

    &--sub {
      .link__text {
        @extend %typo--link--trimmed;
      }

      a,
      button {
        display: grid;
        opacity: 0.3;
        text-decoration: none;
      }

      a.router-link-exact-active {
        opacity: 1;
      }

      @media (hover: hover) {
        &:hover {
          opacity: 1;
          a,
          button {
            opacity: 1;
          }
        }
      }
    }

    &--straight {
      .link__text {
        @extend %typo--link--trimmed;
      }

      a,
      button {
        display: grid;
        text-decoration: none;
      }

      @media (hover: hover) {
        &:hover {
          opacity: 1;
          a,
          button {
            opacity: 1;
          }
        }
      }
    }
  }

  &--navigation {
    .link__text {
      @extend %typo--nav-l--trimmed;
      font-size: 50px;

      @include mq(m) {
        font-size: 60px;
      }

      a {
        display: grid;
        text-decoration: none;
      }
    }
  }

  &--small,
  &--small--invert {
    .link__text {
      @extend %typo--6--trimmed;

      a {
        display: grid;
        text-decoration: none;
      }
    }
  }

  &--small--invert {
    @media (hover: hover) {
      &:hover {
        color: var(--red);
        opacity: 1;
      }
    }
    a,
    button {
      padding: calc(var(--spacer-xs) * 2);
    }

    &.active {
      color: var(--red);
    }
  }

  &--huge {
    @extend %full;

    &.block {
      padding: 0 !important;
    }

    > * {
      padding: var(--spacer-bar-m) var(--spacer-s);
    }

    .link__text {
      border-radius: var(--border-radius);
      column-gap: var(--column-gap-s);
      clip-path: inset(0);
      display: grid;
      grid-template-columns: repeat(var(--main-columns), minmax(0, 1fr));
      transition: clip-path 0.15s ease-out;

      > * {
        @extend %typo--1--trimmed;

        grid-column: 2 / -1;
      }
    }

    &--black {
      background: var(--white);
      color: var(--white);

      .link__text {
        background: var(--black);
      }
    }
    &--red {
      background: var(--black);
      color: var(--white);

      .link__text {
        background: var(--red);
      }
    }
    &--white {
      background: var(--red);

      .link__text {
        background: var(--white);
      }
    }

    @media (hover: hover) {
      &:hover {
        opacity: 1;

        .link__text {
          clip-path: inset(0 30px 0 0);
        }
      }
    }
  }

  &--inline {
    &--small {
      @extend %typo--caption;
    }
  }

  &--disabled {
    opacity: 0.3 !important;
    pointer-events: none !important;
  }
}
</style>

<style lang="scss">
.e + .z,
.s + .t {
  margin-left: 0.03em;
}
</style>
