<template>
  <form @submit.prevent="submit">
    <Input
      type="text"
      name="name"
      required
      @update="updateMail"
    />
    <Input
      type="text"
      name="surname"
      required
      @update="updateMail"
    />
    <Input
      type="email"
      name="email"
      required
      @update="updateMail"
    />
    <File
      name="cv"
      required
      @update="updateMail"
    />
    <Radio
      name="area"
      required
      :options="[
        'research_publishing',
        'exhibition_production',
        'marketing_communication',
        'project_management',
        'collection',
        'administration',
        'education',
      ]"
      @update="updateMail"
    />
    <Textarea
      name="message"
      required
      :columns="2"
      :rows="2"
      @update="updateMail"
    />
    <Checkbox
      name="careers"
      :options="['careers']"
      required
      @update="updateMail"
    />
    <Link
      :style="{ gridColumn: '1 / -1' }"
      theme="inline"
      :data="{
        url: '/privacy',
        title: 'Privacy policy',
      }"
      @click.native.prevent
    />
    <Link
      class="submit"
      theme="highlight"
      :disabled="!valid || response === 'success' || loading"
      :data="{
        fn: () => {},
        title: $labels().form_submit,
      }"
    />
    <div
      v-if="response"
      class="typo--7 response"
    >
      {{ `${$labels()[`form_${response}_message`]}` }}
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';

import elements from '@/components/forms/elements.js';
import Link from '@/components/typo/link';

export default {
  components: {
    ...elements,
    Link,
  },
  props: {
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: {},
      files: {},
      response: '',
    };
  },
  computed: {
    ...mapGetters(['options', 'lang', 'loading']),
  },
  methods: {
    updateMail(name, model, type = false) {
      if (type === 'file') {
        this.files[name] = model;
      } else {
        this.email[name] = {
          label: type === 'choice' ? model : this.$labels()[`form_${name}`],
          value: type === 'choice' ? true : model,
        };
      }
    },
    submit() {
      this.$store.commit('SET_LOADING', true);
      const request = {
        fn: 'form',
        params: {
          name: 'careers',
          subject: this.$labels().form_careers_subject,
          email: this.email,
          language: this.lang,
        },
        file: this.files.cv,
      };

      this.$store
        .dispatch('sendRequest', request)
        .then((data) => {
          if (data.error) {
            this.response = 'error';
            console.log(data.message);
          } else {
            this.response = 'success';
          }
          this.$store.commit('SET_LOADING', false);
        })
        .catch((error) => {
          this.response = 'error';
          console.log(error);
          this.$store.commit('SET_LOADING', false);
        });
    },
  },
};
</script>

<style></style>
