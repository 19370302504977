<template>
  <form
    :is="forms.get(data.value.form)"
    ref="form"
    class="form"
    :valid="valid"
    @input.native="checkValidity"
  />
</template>

<script>
import Press from '@/components/forms/press';
import Careers from '@/components/forms/careers';

export default {
  name: 'Form',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      forms: new Map([
        ['press', Press],
        ['careers', Careers],
      ]),
      valid: false,
    };
  },
  methods: {
    checkValidity() {
      const elements = document.querySelectorAll('.form input');

      let valid = true;

      elements.forEach((element) => {
        if (!element.validity.valid) {
          valid = false;
        }
      });

      this.valid = valid;
    },
  },
};
</script>

<style lang="scss">
.form {
  column-gap: var(--column-gap-s);
  display: grid;
  justify-items: start;
  align-items: start;
  row-gap: var(--row-gap-l);

  &.block {
    @include mq(s) {
      grid-column: 2 / span 6;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include mq(m) {
      grid-column: 4 / span 6;
    }
  }

  .error {
    color: var(--red);
    margin-top: calc(var(--spacer-xs) * 2);
    visibility: hidden;
  }

  input:invalid:not(:focus):not(:placeholder-shown) {
    border-color: var(--red);
    color: var(--red);

    & + .error {
      visibility: visible;
    }
  }

  .response {
    color: var(--red);
  }

  label {
    a {
      text-decoration: underline;
    }
  }
}

form {
  --checkbox-size: 9px;
  --checkbox-inside-size: 9px;

  @include mq(m) {
    --checkbox-size: 14px;
    --checkbox-inside-size: 14px;
  }

  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: content-box;
    border: var(--line) var(--alpha-black) solid;
    cursor: pointer;
    display: grid;
    height: var(--checkbox-size);
    width: var(--checkbox-size);
    place-items: center;

    > * {
      grid-area: 1 / -1;
    }

    &:checked::after {
      background: var(--black);
      content: "";
      height: var(--checkbox-inside-size);
      width: var(--checkbox-inside-size);
    }
  }
}
</style>
