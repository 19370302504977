var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"menu"},[_c('div',{staticClass:"first-row"},[_c('div',_vm._l((_vm.first),function(item,index){return _c('Link',{key:item.id,style:({ zIndex: index }),attrs:{"data":{
          url: item.url,
          title: item.content,
          target: item.target,
        },"theme":"navigation"}})}),1),_c('div',{staticClass:"second-column"},_vm._l((_vm.second),function(item,index){return _c('Link',{key:item.id,style:({ zIndex: index }),attrs:{"data":{
          url: item.url,
          title: item.content,
          target: item.target,
        },"theme":"navigation"}})}),1)]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"social"},_vm._l((_vm.social),function(item){return _c('Link',{key:item.id,attrs:{"data":{
          url: item.url,
          title:
            _vm.$mq.isMobile && item.acf.mobile_label !== ''
              ? item.acf.mobile_label
              : item.content,
          target: item.target,
        },"theme":"small"}})}),1),_c('LanguageSelector')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }