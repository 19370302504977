import { render, staticRenderFns } from "./radio.vue?vue&type=template&id=f9735ec6&scoped=true"
import script from "./radio.vue?vue&type=script&lang=js"
export * from "./radio.vue?vue&type=script&lang=js"
import style0 from "./radio.vue?vue&type=style&index=0&id=f9735ec6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9735ec6",
  null
  
)

export default component.exports