import Checkbox from '@/components/forms/elements/checkbox';
import Input from '@/components/forms/elements/input';
import Radio from '@/components/forms/elements/radio';
import Textarea from '@/components/forms/elements/textarea';
import File from '@/components/forms/elements/file';

export default {
  Checkbox,
  Input,
  Radio,
  Textarea,
  File,
};
