<template>
  <div class="overlay">
    <single
      :is="singles.get(post.type)"
      ref="single"
      class="single"
      :post="post"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Exhibition from '@/views/singles/exhibition';
import Event from '@/views/singles/event';
import Work from '@/views/singles/work';

import data from '@/mixins/data';

export default {
  name: 'Single',
  mixins: [data],
  data() {
    return {
      singles: new Map([
        ['exhibition', Exhibition],
        ['event', Event],
        ['work', Work],
      ]),
    };
  },
  mounted() {
    if (!this.$store.state.route.from.name) {
      this.lockView({ view: '.page-container' });
    }
  },
  methods: {
    ...mapActions(['lockView']),
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: relative;
  z-index: 2;
  width: 100%;
}
.single {
  width: 100%;
  align-content: start;
  background: var(--white);
  display: grid;
  column-gap: var(--column-gap-s);
  grid-template-columns: repeat(var(--main-columns), minmax(0, 1fr));
}
</style>
